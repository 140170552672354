@import '~bootstrap/scss/bootstrap.scss';

// Typography
h1 {
	@include h1Fonts();
}

h2 {
	@include h2Fonts();
}

h3 {
	@include h3Fonts();
}

p,
.form-control {
	@include textBody();
}

small {
	line-height: 1 !important;
}

// Nav
.navbar {
	margin-bottom: 3em;
	padding: 0.5em 0;
	box-shadow: 2px 4px 24px $lightGrey;

	@media only screen and (max-width: $breakpointMd) {
		margin-bottom: 2em;
		padding: 0.5em 10px;
	}
}

.navbar-brand {
	margin-right: 2em;
}

.nav-item {
	display: flex;
	align-items: center;
}

.nav-link,
.dropdown-item {
	@include textBody();
	margin-bottom: 0;
	cursor: pointer;
}

// Forms
.dropdown-item.active,
.dropdown-item:active {
	background-color: $lightGrey;
}

// Colors
.bg-lightred {
	background-color: #dc354533 !important;
}

// UI
.card {
	border: 0;
	box-shadow: 2px 4px 24px $lightGrey;
	z-index: 1;

	.card-title {
		font-size: 14px;
		line-height: 1.4;
	}

	.card-header {
		padding: 0.75rem;
		background-color: $white;
	}

	.card-body {
		border-radius: 0.25em;
	}
}

.table {
	border-top: 0;

	thead {
		border-bottom: 0;
		background-color: #fafafa;
		color: $darkGrey;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: normal;

		th {
			border-bottom: 1px solid #dee2e6;
			border-top: 0;

			p {
				font-family: $circularMedium;
				font-weight: initial;
			}
		}
	}
}

.bg-grey {
	background-color: $grey;
}

.btn-outline-grey {
	color: $grey;
	border-color: $grey;

	&:hover {
		color: $white;
		background-color: $grey;
	}
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #212529 !important;
}

.badge {
	font-weight: normal;
}

.alert-dismissible .close {
	padding: 0.5rem 1.25rem;
}

.overflow-scroll {
	overflow: scroll;
}
